import React from 'react';
import Chart from '../Components/Chart/Chart';

export const ChartPage = () => (
  <>
    <main>
      <Chart></Chart>
    </main>
  </>
)


export default ChartPage;
