import React from 'react';
import Header from '../Components/Header/Header';
import Product from '../Components/Product/Product';


export const ProductPage = () => (
  <>
    <Header />
    <main>
      <Product />
    </main>
  </>
)


export default ProductPage;
